// nieuzywany


<template>
  <div>
    <div class="row bg-light mt-1 ml-0">
      <div class="col-6">
        <router-link to="/">
          <button style="width: 10%" class="btn btn-info mt-1 mb-1 float-left">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-calendar-date"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
              />
              <path
                d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"
              />
            </svg>
            <br />
            <!-- Dodaj -->
          </button>
        </router-link>
          <router-link to="/services">
          <button
            style="width: 10%"
            class="btn btn-info ml-1 mt-1 mb-1 float-left"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-wrench"
              viewBox="0 0 16 16"
            >
              <path
                d="M.102 2.223A3.004 3.004 0 0 0 3.78 5.897l6.341 6.252A3.003 3.003 0 0 0 13 16a3 3 0 1 0-.851-5.878L5.897 3.781A3.004 3.004 0 0 0 2.223.1l2.141 2.142L4 4l-1.757.364L.102 2.223zm13.37 9.019l.528.026.287.445.445.287.026.529L15 13l-.242.471-.026.529-.445.287-.287.445-.529.026L13 15l-.471-.242-.529-.026-.287-.445-.445-.287-.026-.529L11 13l.242-.471.026-.529.445-.287.287-.445.529-.026L13 11l.471.242z"
              />
            </svg>

            <br />
          </button>
        </router-link>
        <router-link to="/eventlist">
          <button
            style="width: 10%"
            class="btn btn-info ml-1 mt-1 mb-1 float-left"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-list-ol"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"
              />
              <path
                d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z"
              />
            </svg>
            <br />
          </button>
        </router-link>
      </div>
    </div>
    <vue-cal
      :events="events"
      :on-event-click="openInstallationModal"
      :disable-views="['years', 'year']"
      active-view="month"
      events-on-month-view="short"
      locale="pl"
    >
    </vue-cal>
    <installation-modal :installation="installation"></installation-modal>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/i18n/pl.js";
import "vue-cal/dist/vuecal.css";
import { mapState } from "vuex";
import InstallationModal from "../components/InstallationModal";
export default {
  data() {
    return {
      selectedEvent: {},
      showDialog: false,
      events: [],
      installation: "",
    };
  },
  components: {
    VueCal,
    "installation-modal": InstallationModal,
  },
  methods: {
    openInstallationModal(event, e) {
      this.$modal.show("installation-modal");
      this.installation = event;
      // Prevent navigating to narrower view (default vue-cal behavior).
      e.stopPropagation();
    },
    setEvents() {
      function Event(start, end, title, content, installation, classStyle) {
        (this.start = start),
          (this.end = end),
          (this.title = title),
          (this.content = content);
        this.contractor = installation.contractor;
        this.installation = installation;
        this.class = classStyle
      }

      this.installations.forEach((doc) => {
        console.log(doc.service)
        if(doc.service){
          let event = new Event(
          doc.service,
          doc.service,
          doc.contractor.label + "|" + doc.squad,
          doc.notice,
          doc,
          "przeglad"
        );
        this.events.push(event);
        } 
        // console.log(event)
      });
    },
  },
  computed: {
    ...mapState(["userProfile", "installations"]),
  },
  mounted() {
    setTimeout(() => {
      //   //   const item1 = this.config.chart.items["1"];
      //   //   item1.label = "label changed dynamically";
      //   //   item1.time.end += 2 * 24 * 60 * 60 * 1000;
      this.setEvents();
    }, 100);
  },
};
</script>
<style>
.vuecal__cell {
  min-height: 100px;
}
.vuecal__cell-date {
  position: absolute;
  top: 3px;
  right: 5px;
}

.vuecal__event.leisure {
  background-color: rgba(253, 156, 66, 0.9);
  border: 1px solid rgb(233, 136, 46);
  color: #fff;
}
.vuecal__event.sport {
  background-color: rgba(255, 102, 102, 0.9);
  border: 1px solid rgb(235, 82, 82);
  color: #fff;
}
.vuecal__event.test {
  background-color: gold;
}
.vuecal__event.przeglad {
  background-color: rgb(123, 165, 243);
}
</style>
